<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <h3>
          <z-back-button class="float-left" @click.native="back" />
          Dokončenie objednávky
        </h3>

        <b-tabs id="tabs" v-model="activeTab" card>
          <b-tab v-for="(student, i) in uniqueOrderRegistrations" :key="i">
            <template #title>
              <div class="d-flex align-items-center">
                Študent {{ i + 1 }}
              </div>
            </template>

            <b-form-group label="Študent">
              <b-form-select v-model="student.student_id" :disabled="selectedStudents.length < 2">
                <b-form-select-option
                  v-for="s in selectedStudents" :key="s.id"
                  :value="s.id"
                >{{ s.name + ' ' + s.surname }}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <w-input :value="productName(student)" :label="orderProductType == 'gamecamp' ? 'Tábor' : 'Kurz'" :readonly="true"/>

            <w-input :value="productPeriodPlaceString(student)" label="Miesto konania" :readonly="true" />
        <w-input
          :value="
            productPeriodString(orderCalendarOrderRegistration(student.fe_custom_data.student_index, orderProductType))
          "
          label="Termín konania"
          :readonly="true"
        />

            <b-form-group :label="'Objednávka ' + (orderProductType == 'gamecamp' ? 'táborov' : 'kurzov')">
              <a-product-period-setting
                :order-registration="orderCalendarOrderRegistration(student.fe_custom_data.student_index, orderProductType)"
                :readonly="true"
              />

              <b-form-group class="mt-3" label="Poznámka" description="Nepovinný údaj">
                <b-form-textarea v-model="orderNote" rows="3" max-rows="6" />
              </b-form-group>

              <w-input tag="b-form-checkbox" v-model="$v.orderConsent" id="consentCheckbox">

            <div v-if="orderProductType == 'gamisfera-licencia'">
              <span>
                <a target="_blank" href="../podmienky/Gamisfera-licenčná zmluva.pdf">
                  <strong>Podmienky poskytnutia licencie</strong>
                </a>
              </span>
            </div>
            <div v-else-if="orderProductType == 'vytvor-si-hru'">
              <span> Súhlasím so všeobecnými podmienkami produktu, ktorý som si objednal<br />
                <a target="_blank" href="../podmienky/Vseob.Podmienky_Vytvor Si Hru_Online_22_23.pdf">
                  <strong>Všeobecné podmienky účasti na kurze Vytvor si hru</strong>
                </a>, <br/>
                <a target="_blank" href="../podmienky/Vseob.podmienky_Vytvor Si Hru_22_23_SENEC.pdf">
                  <strong>Všeobecné podmienky účasti na kurze Vytvor si hru - Senec</strong>
                </a>
              </span>
            </div>
            <div v-else-if="isCourse">
                  <span>
                    Súhlasím so všeobecnými podmienkami produktu, ktorý som si objednal
                <br />
                    <a v-if="productName(student).includes('GameCraft Online')" target="_blank" href="../podmienky/Všeob.podmienky_GameCraft_Online_22_23.pdf">
                      <strong>Všeobecné podmienky účasti na kurze GameCraft Online</strong>
                    </a>
                    <a v-else target="_blank" href="../podmienky/Všeob.podmienky_GameCraft_21_22.pdf">
                      <strong>Všeobecné podmienky účasti na kurze GameCraft</strong>
                    </a>,
                  </span>
                  <br>
<!--                  <a target="_blank" href="../podmienky/MojaPrvaHra_ Všeob.podmienky_GameCraft21_22.pdf">-->
<!--                <strong>Všeobecné podmienky účasti na kurze Moja Prvá Hra</strong> </a>,-->
              <br />
              <a v-if="orderProductType == 'vytvor-si-hru' || orderProductType == 'moja-prva-hra' || orderProductType == 'gamecraft-na-skolach' || orderProductType == 'gamecraft-na-skolach-60min'"
                    target="_blank" href="../podmienky/Vseob.podmienky_Vytvor_Si_Hru_Online_ 22_23.pdf">
                    <strong>Všeobecné podmienky účasti na kurze Vytvor Si Hru</strong>
                  </a>
                </div>
                <div v-else-if="orderProductType == 'sgd'">
                  Zaväzujem sa, že budem letnej škole venovať aspoň 20 hodín týždenne v mieste konania.
                </div>
            <div v-else-if="isLevelUp">
                  <span>
                    Súhlasím so všeobecnými podmienkami produktu, ktorý som si objednal
                <br />
                <a target="_blank" href="../podmienky/podmienky-sgd-academy-2022.pdf">
                  <strong>Všeobecné podmienky účasti na kurze SGD Academy</strong> </a
                ><br />
                    <a target="_blank" href="../podmienky/podmienky_Levelup_your_skills_2021.pdf">
                  <strong>Všeobecné podmienky účasti na kurze LevelUp Your Skills</strong> </a
                ><br />
                    <a target="_blank" href="https://hemisfera.sk/vseobecne-podmienky-levelup">
                      <strong>Všeobecné podmienky účasti na kurze LevelUp</strong>
                    </a>
                  </span>
                </div>
            <div v-else>
              <span>
                Súhlasím so všeobecnými podmienkami produktu, ktorý som si objednal
                <br />
                <a target="_blank" href="../podmienky/podmienky_GameCamp_Bratislava_2022.pdf">
                  <strong>GameCamp Bratislava 2022</strong> </a>
                |
                <a target="_blank" href="../podmienky/podmienky_GameCamp_Zilina_2022.pdf">
                  <strong>GameCamp Žilina 2022</strong> </a>
                |
                <a target="_blank" href="../podmienky/podmienky_GameCamp_Kosice_2022.pdf">
                  <strong>GameCamp Košice 2022</strong> </a>
                |
                <a target="_blank" href="../podmienky/podmienky_GameCamp_Presov_2022.pdf">
                  <strong>GameCamp Prešov 2022</strong> </a>
                |
                <a target="_blank" href="../podmienky/podmienky_TechArtCamp_2022.pdf">
                  <strong>TechArtCamp 2022</strong> </a>
              </span>
            </div>
              </w-input>
          <div v-if="selectedStudentsUnder16">
                <w-input tag="b-form-checkbox" v-model="$v.orderAccountConsent" id="accountConsentCheckbox">
                  <span>
                    Súhlasím s vytváraním internetových účtov na účely vzdelávania môjho dieťaťa
                <br />
                    <a target="_blank" href="../podmienky/Suhlas-internet.účty-GameCraft21_22.pdf">
                      <strong>SÚHLAS rodiča / zákonného zástupcu</strong>
                    </a>
                  </span>
                </w-input>
              </div>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
        <div class="col-md-4" :class="{'price-loading': orderPriceLoading}">
          <div class="sticky-top sticky-offset">
            <span v-if="orderPriceLoading" class="spinner-border spinner-border-sm price-spinner" role="status" aria-hidden="true"></span>
            <div class="mb-3 text-small">
              <a-order-student-overview-collapse
                :class="{active: activeTab == i}"
                v-for="(registration, i) in uniqueOrderRegistrationsWithPrices" :key="i"
                :index="i + 1"
                :order-registration="registration"
              />
            </div>
            <a-payment ref="paymentSelection" @onPaymentTypeChanged="changeButtonText"/>
            <b-button class="z-submit-btn w-100" variant="success" :disabled="$wToast.isLoading()" @click="onNext">
              <div v-if="$wToast.isLoading()">
                <span class="spinner-border spinner-border-sm price-spinner" role="status" aria-hidden="true"></span>
              </div>
              <div v-else>
                {{nextButtonText}}
              </div>
            </b-button>
        </div>
      </div>
    </div>
    <b-modal id="unsaved-changes-dialog" @ok="leaveOrder" :centered="true" hide-header>
        <p> Ak opustite túto stránku stratíte údaje z objednávky. </p>
    </b-modal>
  </div>

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import moment from 'moment'
import productUtils from "@/plugins/app/_utils/product.util.js"



export default {
  components: {
    "a-product-period-setting":           () => import("@/plugins/app/_features/product-order/a-product-period-setting.vue"),
    'a-order-student-overview-collapse':  () => import('@/plugins/app/order/components/a-order-student-overview-collapse.vue'),
    'a-payment' :               () => import('./components/a-payment.vue')
  },

  data() {
    return {
      selectedStudents: [],
      activeTab: 0,
      nextButtonText: "Zaplatiť kartou"
    }
  },

  validations() {
    return this.rules
  },

  async mounted() {
    this.$wToast.stopLoading()
    window.scrollTo(0, 0)

    try {
      // refresh user token before loading order
      await this.$store.dispatch("wAuth/userInfo")
      await this.$store.dispatch("order/getOrder")
      await this.$store.dispatch("order/getNewPrices")
      this.selectedStudents = this.uniqueOrderRegistrations.map((s) => s.student)
    } catch (e) {
      this.$wToast.error(e)
    }
    this._trackDataLayerEvent()
  },

  async beforeRouteLeave(to, _, next) {
      if((to.name != "Acknowledgment" && to.name != "ProductTypeSelect")
          && localStorage.getItem('checkoutUrl')){

        this.$bvModal.show("unsaved-changes-dialog")
        return next(false)
      }

      return next()

  },

  computed: {
    ...mapFields("order", {
      orderConsent: "consent",
      orderAccountConsent: "orderConsent",
      orderPrice: "orderPrice",
      orderPriceLoading: 'priceLoading',
      orderOrderRegistrations: 'orderRegistrations',
      orderId: 'orderId',
      orderNote: 'note',
    }),
    ...mapGetters("order", {
      orderCalendarOrderRegistration: "calendarOrderRegistration",
      orderProductType: "orderProductType",
      orderRegistrationsForStudentIndex: "orderRegistrationsForStudentIndex",
    }),

    isCourse() {
      return productUtils.isCourse(this.orderProductType)
    },
    isLevelUp() {
      return productUtils.isLevelUp(this.orderProductType)
    },

    uniqueOrderRegistrationsWithPrices() {
      if (!this.orderPrice.order_once_pay)
        return []

      return this.orderPrice.order_once_pay.order_registrations.reduce((unique, or) => {
        if (unique.some(o => o.fe_custom_data.student_index == or.fe_custom_data.student_index))
          return unique

        unique.push(or)

        return unique
      }, [])
    },

    selectedStudentsUnder16() {
      return this.selectedStudents.some(
        (student) => Math.abs(moment(student.birth_year, "YYYY").diff(moment().format("YYYY"), "years")) < 16
      )
    },

    selectedStudentsValid() {
      const studentIds = this.uniqueOrderRegistrations.map(s => Number(s.student_id))
      return [...new Set(studentIds)].length == studentIds.length
    },

    uniqueOrderRegistrations() {
      return this.orderOrderRegistrations.reduce((unique, or) => {
        if (unique.some(o => o.fe_custom_data.student_index == or.fe_custom_data.student_index))
          return unique

        unique.push(or)
        return unique
      }, [])
    },
    rules() {
      const rules = {
        orderConsent: {
          agreed(val) {
            return val
          },
        },
        orderAccountConsent: {
          agreed(val) {
            return val
          },
        },
      }
      if (!this.selectedStudentsUnder16) {
        delete rules.orderAccountConsent
      }
      return rules
    },
  },

  methods: {
    changeButtonText(isCardPayment){
      if(isCardPayment){
        this.nextButtonText = "Zaplatiť kartou"
        return
      }

      this.nextButtonText = "Dokončiť a zaplatiť prevodom"
    },

    productPeriodPlaceString(orderRegistration) {
      const place = orderRegistration.product_period.place
      return place
        ? `${place.name}, ${place.street}${place.zip != 0 ? place.zip + ", " : ""}${
            place.town ? place.town + ", " : ""
          }`
        : ""
    },

    productPeriodString(orderRegistration) {
      return this.orderRegistrationsForStudentIndex(orderRegistration.fe_custom_data.student_index)
        .map((or) => {
        return `${this._formatDate(or.product_period.start_date)} - ${this._formatDate(or.product_period.end_date)}`
        })
        .join(", ")
    },

    productName(student) {
      return (student.product_period.product && student.product_period.product.name) || ""
    },

    async onNext() {
      if (!this.orderConsent) {
        this.$v.$touch()
        document.getElementById("consentCheckbox").scrollIntoView()
        return
      }

      if (!this.orderAccountConsent && this.selectedStudentsUnder16) {
        this.$v.$touch()
        document.getElementById("accountConsentCheckbox").scrollIntoView()
        return
      }

      if (!this.selectedStudentsValid) {
        this.$wToast.error('Jeden študent je priradený k viacerým registráciám.')
        return
      }

      this.$wToast.clear_loading()
      this.$refs.paymentSelection.payAndRedirect()
    },

    back() {
      this.$router.push({ name: "StudentsInformation" })
    },

    leaveOrder() {
      this.$router.push({ name: "ProductTypeSelect" })
    },

    _formatDate(date) {
      return moment(date).format("DD.MM.YYYY")
    },

    _trackDataLayerEvent() {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "checkout_progress",
        pageTitle: "Dokončenie objednávky",
        product: this.orderOrderRegistrations ? this.orderOrderRegistrations[0].product_period.product : "",
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.z-submit-btn
  border-radius: 2px
.price-loading
  position: relative

  .sticky-top
    &:after
      content: ""
      width: 100%
      height: 100%
      background: #fafaf8
      opacity: .6
      position: absolute
      left: 0
      top: 0
      z-index: 999

  .price-spinner
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50% -50%)
    z-index: 9999
</style>
